<template>
  <div style="display: inline">
    <button class="col-sm-12"
    :class="color"
        @click="openModalPrecierre">Pre Cierre
    </button>
    <b-modal
    v-model="shoModalPrecierre"
    size="lg"
    title="Pre Cierre de Tratamientos"
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close>
    <div class="row mb-2">
      <div class="col-sm-3">
        <div class="form-group">
          <label></label>
          <div class="col-sm-12">
            <button
              :class="colorCalcular" @click="calcular">Calcular
            </button>
          </div>
        </div>
      </div>
      <div class="col-sm-1"></div>
      <div class="col-sm-2 pr-0">
        <div class="form-group">
          <label>Total:</label>
          <vue-numeric class="form-control"
            :disabled="true"
              v-model="total"
              separator=","
              :precision="2"
              placeholder=""/>
        </div>
      </div>
      <div class="col-sm-2 pr-0">
        <div class="form-group">
          <label>ANTICIPO:</label>
          <vue-numeric class="form-control"
            :disabled="true"
            v-model="anticipo"
            separator=","
            :precision="2"
            placeholder=""/>
        </div>
      </div>
      <div class="col-sm-2 pr-0">
        <div class="form-group">
          <label>SALDO:</label>
          <vue-numeric class="form-control"
            :disabled="true"
            v-model="saldo"
            separator=","
            :precision="2"
            placeholder=""
            :minus="true"/>
            <!-- <input type="text" class="form-control" v-model="saldo"
            :disabled="true"> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-10">
        <div class="form-group row">
          <div class="col-sm-12">
            <label for="moneda_id"
              class="col-form-label text-right" >Glosa:</label >
          </div>
          <div class="col-sm-12">
            <b-form-textarea
              id="glosa"
              class="form-control no-resize"
              v-model="glosa"
              placeholder="Ingrese alguna descripcion."
              rows="3"
              max-rows="4"
              style="overflow-y: auto;"/>
          </div>
        </div>
      </div>
      <div class="col-sm-4 text-left">
        <button class="btn btn-warning" type="button"
        v-show="btnSuspender"
        @click="restaurar">
          Suspender
        </button>
      </div>
      <div class="col-sm-8 text-right pr-4">
        <button
          type="button"
          class="btn btn-secondary mr-2"
          @click="shoModalPrecierre = false">
          Cerrar
        </button>
        <button
          type="button"
          class="btn btn-success mr-2"
          :disabled="btnSuspender"
          @click="agregarPrecierre">
          Guardar Pre Cierre
        </button>
        <button
          type="button"
          class="btn btn-success mr-2"
          :disabled="disableCierre"
          @click="agregarCierre">
          Guardar Cierre
        </button>
      </div>
    </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import VueNumeric from 'vue-numeric';
import { util } from '../../../plugins/util';
// import axios from '../../common/axiosERP';

export default {
  name: 'TheModalPreCierre',
  props: {
    servicios_internos: {
      type: Array,
      required: true,
      default: Array,
    },
    cxp: {
      type: Array,
      required: true,
      default: Array,
    },
    salidProducto: {
      type: Array,
      required: true,
      default: Array,
    },
    paciente: {
      type: Object,
      required: false,
      default: Object,
    },
    preCierreData: {
      type: Object,
      default: Object,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    moneda: {
      type: Object,
      default: Object,
    },
  },
  data() {
    return {
      shoModalPrecierre: false,
      total: 0,
      anticipo: 0,
      saldo: 15,
      glosa: null,
      // serviciosInternos: this.servicios_internos,
      precierre: false,
      color: 'btn btn-warning',
      colorCalcular: 'btn btn-danger',
      btnCalcular: false,
      btnSuspender: false,
      fecha: moment().format('DD/MM/YYYY'),
      hora: null,
      disableCierre: true,
    };
  },
  created() {
    if (this.editMode) {
      // this.cargarDatosModal();
      if (this.preCierreData.estado_precierre === 1
        || this.preCierreData.estado_precierre === null) {
        this.btnCalcular = true;
        this.colorCalcular = 'btn btn-success';
        this.color = 'btn btn-success';
        this.btnSuspender = true;
      }
    }
  },
  methods: {
    openModalPrecierre() {
      this.cargarDatosModal();
      this.shoModalPrecierre = true;
    },
    calcular() {
      if (!this.paciente.id) {
        util.showNotify('Debe Seleccionar un paciente', 'error');
        return;
      }
      const TOTAL = parseFloat(this.serviciosInternos) + parseFloat(this.centasXPagar)
      + parseFloat(this.consumoProducto);
      this.total = TOTAL;
      // this.anticipo = (this.paciente.anticipo) ? this.paciente.anticipo : 0;
      const SALDO = parseFloat(this.total) - parseFloat(this.anticipo);
      this.saldo = SALDO;
      this.colorCalcular = 'btn btn-success';
      this.btnCalcular = true;
    },
    agregarPrecierre() {
      if (this.btnCalcular) {
        if (this.total === 0) {
          util.showNotify('No se puede agregar un Pre Cierre con con monto 0', 'error');
          return;
        }
        this.precierre = true;
        this.color = 'btn btn-success';
        const data = {
          total: this.total,
          anticipo: this.anticipo,
          saldo: this.saldo,
          isPrecierre: this.precierre,
          glosa: this.glosa,
          estado_pre_cierre: true,
          custom_id: new Date().getTime(),
          hora: moment().format('HH:mm:ss'),
          fecha: this.fecha,
        };
        this.btnSuspender = true;
        this.disableCierre = false;
        this.$emit('agregarPrecierre', data);
        // this.shoModalPrecierre = false;
      } else {
        util.showNotify('Debe volver a calcular su preCierre', 'error');
      }
    },
    restaurar() {
      this.precierre = false;
      this.color = 'btn btn-warning';
      this.btnCalcular = false;
      this.colorCalcular = 'btn btn-danger';
      this.limpiarPreCierre();
      this.$emit('update:estado_precierre', this.precierre);
      this.$emit('limpiarPreCierre');
      this.btnSuspender = false;
    },
    cargarDatosModal() {
      // if (this.preCierreData.id) {
      this.total = this.preCierreData.total_precierre;
      this.anticipo = (this.paciente.anticipo) ? this.paciente.anticipo : 0;
      this.saldo = this.preCierreData.saldo_precierre;
      this.glosa = this.preCierreData.glosa_precierre;
      // }
    },
    limpiarPreCierre() {
      this.total = 0;
      // this.anticipo = 0;
      this.saldo = 0;
      this.glosa = null;
    },
    agregarCierre() {
      const DATA = {
        fecha: this.fecha,
        hora: moment().format('HH:mm:ss'),
        glosa: 'Cierre Automatico',
        estado: true,
      };
      this.disableCierre = true;
      this.shoModalPrecierre = false;
      this.$emit('agregarCierre', DATA);
    },
  },
  computed: {
    serviciosInternos() {
      let total = 0;
      this.servicios_internos.forEach((item) => {
        total += parseFloat(item.importe);
      });
      return total;
    },
    centasXPagar() {
      let total = 0;
      this.cxp.forEach((item) => {
        const tipoMoneda = this.moneda.multiselect.find(
          (el) => el.id === item.moneda_id,
        );
        if (tipoMoneda.is_principal === 0) {
          total += parseFloat(item.importe) * parseFloat(tipoMoneda.tc);
        } else {
          total += parseFloat(item.importe);
        }
      });
      return total;
    },
    consumoProducto() {
      let total = 0;
      this.salidProducto.forEach((item) => {
        if (item.tipo_consumo === 2) {
          total += parseFloat(item.total_venta);
        }
      });
      return total;
    },
  },
  watch: {
    servicios_internos() {
      this.restaurar();
    },
    cxp() {
      this.restaurar();
    },
  },
  components: {
    VueNumeric,
  },
};
</script>
