function load(data, id) {
  for (let i = 0; i < data.length; i += 1) {
    if (data[i].id === id) {
      return data[i];
    }
  }
  return null;
}

function parseDateToYmd(date) {
  const arr = date.split('/');
  if (arr.length === 3) {
    return `${arr[2]} - ${arr[1]} - ${arr[0]}`;
  }
  return null;
}

function getNumberFromString(texto) {
  if (texto) {
    const r = /\d+/;
    const coincidencias = texto.match(r);
    if (coincidencias) {
      return coincidencias[0];
    }
  }
  return null;
}

export { load, getNumberFromString, parseDateToYmd };
