<template>
  <div style="display: inline">
    <button
      class="btn btn-success"
      @click="showModalEvent"
      :disabled="havePreCierre"
      :title="havePreCierre ? messagePreCierre : ''"
      type="button"> + </button>
      <b-modal v-model="showModal"
      size="lg"
      title="Consumo de Productos"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close >
        <div class="row">
          <div class="col-sm-7">
            <div class="form-group row">
              <div class="col-sm-12">
                  <label for="moneda_id"
                  class="col-form-label text-right" >Productos:</label >
              </div>
              <div class="col-sm-12">
                <multiselect2
                  v-model="salidaProducto.producto"
                  track-by="nombre" label="nombre"
                  :loading="load_multiselect_product"
                  :internal-search="false"
                  placeholder="Seleccione Producto"
                  :options="productosStatic"
                  :allow-empty="false"
                  :show-labels="false"
                  :custom-label="customLabelProduct"
                  @search-change="busquedaAsincronaProductos"
                  @select="getDataProducto">
                  <span slot="caret" v-if="!(salidaProducto.producto===null)"></span>
                  <span slot="noResult">Sin Resultados</span>
                  <span slot="noOptions">Lista Vacia</span>
                  <template slot="singleLabel" slot-scope="{ option }">
                      {{ option.nombre }}
                  </template>
                  <template slot="option" slot-scope="props">
                    {{ props.option.codigo }} - {{ props.option.nombre }} -
                    <span style="color: red; font-weight: bold">
                      {{ props.option.sub_grupo }}
                    </span>
                  </template>
                  <template slot="afterList">
                      <div class="row">
                          <a
                          v-if="showCargarMas"
                          href="javascript:void(0)"
                          class="col-sm-12 pl-5 py-1 text-dark font-weight-bold"
                          @click="getListProduct(null)">
                          Cargar mas...
                          </a>
                      </div>
                  </template>
                </multiselect2>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group row">
              <div class="col-sm-12">
                  <label for="moneda_id"
                  class="col-form-label text-right" >Unidad:</label >
              </div>
              <div class="row col-sm-12">
                <div class="col-sm-5">
                  <label class="radio radio-primary">
                      <input type="radio" v-model="salidaProducto.unidad" value="10" ref="unidad">
                      <span>
                        {{ (salidaProducto.producto && salidaProducto.producto.unidad_medida )?
                          salidaProducto.producto.unidad_medida.descripcion : '' }}
                      </span>
                      <span class="checkmark"></span>
                    </label>
                      <!-- <template
                        v-if="salidaProducto.producto
                          && salidaProducto.producto.unidad_medida_alternativa">
                          <label class="radio radio-primary">
                            <input type="radio" value="10" ref="unidad2">
                            <span>
                              {{ salidaProducto.producto.unidad_medida_alternativa.descripcion }}
                            </span>
                            <span class="checkmark"></span>
                          </label>
                          <strong class="row">
                            Conv.: {{salidaProducto.producto.conversiones}}</strong>
                      </template> -->
                </div>
                <div class="col-sm-7">
                  <span style="line-height: inherit" class="badge badge-pill badge-primary ml-4">
                    <!-- salidaProducto.stock.saldo_up -->
                    Stock: {{salidaProducto.stock ? salidaProducto.stock.saldo_up : 0}}
                </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group row">
              <div class="col-sm-12">
                  <label for="cantidad"
                  class="col-form-label text-right" >Cantidad:</label>
              </div>
              <div class="col-sm-9">
                <input type="text"
                  v-model="salidaProducto.cantidad"
                  @keypress="onlyNumber" @paste.prevent
                  class="form-control">
                </div>
            </div>
          </div>
          <div class="col-sm-7">
            <div class="form-group row">
              <div class="col-sm-12">
                  <div class="col-sm-12">
                    <label for="moneda_id"
                      class="col-form-label text-right" >Glosa:</label >
                  </div>
                  <div class="col-sm-12">
                    <b-form-textarea
                      id="glosa"
                      class="form-control no-resize"
                      v-model="salidaProducto.glosa"
                      placeholder="Ingrese alguna descripcion."
                      rows="3"
                      max-rows="4"
                      style="overflow-y: auto;"/>
                  </div>
              </div>
            </div>
          </div>
          <div class="col-sm-5">
            <div class="form-group row mt-4">
              <template v-if="sugerencia_glosas.length > 0">
                <div class="col-sm-4 mt-2 pl-0" v-for="(gloss, index) in sugerencia_glosas"
                  v-bind:item="gloss"
                  v-bind:index="index"
                  v-bind:key="index">
                  <span @click="agregarTextoAGlosa(gloss)"
                    style="cursor: pointer; line-height: inherit"
                    class="badge badge-pill badge-primary ml-2 p-1">
                      {{ gloss.nombre }}
                  </span>
                </div>
              </template>
            </div>
          </div>
            <div class="col-sm-12 text-right pr-4">
              <button
                type="button"
                class="btn btn-secondary mr-2"
                @click="showModal = false">
                Cerrar
              </button>
              <button
                type="button"
                class="btn btnOrange mr-2"
                :disabled="load_multiselect_product"
                @click="saveProductos(1)">
                Insumo
              </button>
              <button
                type="button"
                class="btn btnTurquesa"
                :disabled="load_multiselect_product"
                @click="saveProductos(2)">
                Venta
              </button>
            </div>
        </div>
      </b-modal>
  </div>
</template>

<script>
import Multiselect2 from '@/components/util/vue-multiselect2';
import { mapState } from 'vuex';
import axios from '@/modules/common/axiosERP';
import moment from 'moment';
import { util } from '../../../plugins/util';

export default {
  name: 'TheModalConsumoProductos',
  mounted() {
    this.$parent.$on('delDetalle', this.delDetalle);
  },
  props: {
    almacen: {
      type: Object,
      default: null,
    },
    moneda: {
      type: Object,
      default: null,
    },
    sugerencia_glosas: {
      type: Array,
      default: Array,
    },
    fecha: null,
    detalles: {
      type: Array,
      default: Array,
    },
    havePreCierre: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      productosStatic: [],
      salidaProducto: {
        producto: null,
        unidad: true,
        cantidad: 1,
        glosa: '',
        stock: { saldo_ua: 0, saldo_up: 0 },
        stockLotes: [],
        usuario: '',
        fecha: '',
      },
      detalle: {},
      stockLotes: [],
      load_multiselect_product: false,
      temporaPageComboProducto: 0,
      filter: '',
      totalRegistro: '',
      messagePreCierre: 'Acción no permita usted tiene un precierre',
    };
  },
  methods: {
    showModalEvent() {
      this.reset();
      this.showModal = true;
      this.getListProduct(null);
    },
    getListProduct(query) {
      this.filter = query;
      this.temporaPageComboProducto += 1;
      const params = {
        page: 1,
        perPage: 100 * this.temporaPageComboProducto,
        filterKey: this.filter,
      };
      axios.get('/configuration/tipoProducto/combo', { params })
        .then((response) => {
          const { data } = response.data;
          this.productosStatic = data.data;
          this.totalRegistro = data.total;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveProductos(tipo) {
      if (this.validate()) {
        this.agregarDetalle(tipo);
        this.reset();
        this.$emit('addProducto', this.detalles);
      }
    },
    customLabelProduct({ codigo, nombre, subGrupo }) {
      return `${codigo} - ${nombre} - ${subGrupo}`;
    },
    busquedaAsincronaProductos(query) {
      this.temporaPageComboProducto = 0;
      const DELAY = 400;
      clearTimeout(this.delayTimeout);
      this.delayTimeout = setTimeout(() => this.getListProduct(query), DELAY);
    },
    getDataProducto(producto) {
      // this.load_multiselect_product = true;
      if (!this.almacen) {
        util.showNotify('Debe seleccionar un almacen', 'warn');
        return;
      }
      const data = {
        almacen_id: this.almacen.id,
        producto_id: producto.id,
        fecha: moment(this.fecha).format('DD/MM/YYYY'),
        moneda_id: this.moneda.id,
      };
      // /inventario/traspaso_producto/producto/stock_lotes
      axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
      axios.get('/inventario/traspaso_producto/producto/stock_lotes', { params: data })
        .then((response) => {
          const DATA2 = response.data.data;
          // console.log('DATA2', DATA2);
          this.salidaProducto.stock.saldo_up = Math.round(DATA2.stock.saldo_up);
          this.salidaProducto.stock.saldo_ua = Math.round(DATA2.stock.saldo_ua);
          this.salidaProducto.producto = DATA2.producto;
          this.$refs.unidad.checked = true;
          if (DATA2.stock_lotes.length > 0) {
            this.stockLotes = DATA2.stock_lotes;
          }
          this.actualizarStock();
        })
        .catch((error) => {
          console.error(error);
          if (error.response) {
            const response = error.response.data;
            switch (response.error) {
              case 6: {
                util.showNotify(response.message, 'warn');
                this.reset();
                break;
              }
              case 7: {
                util.showNotify(response.message, 'warn', response.data.title);
                this.reset();
                break;
              }
              default: {
                console.log('error', response.error);
                const message = (response.error) ? response.message : 'Error, hubo problemas con el servidor, contactese con el Administrador.';
                util.showNotify(message, 'error');
              }
            }
          } else if (error.request) {
            util.showNotify('La solicitud se realizó pero no se recibió respuesta del servidor.', 'error');
          } else {
            util.showNotify('Algo sucedio al solicitar la solicitud al servidor.', 'error');
          }
        });
    },
    agregarTextoAGlosa(item) {
      if (this.salidaProducto.glosa) {
        const texto = this.salidaProducto.glosa;
        this.salidaProducto.glosa = `${texto} , ${item.nombre}`;
      } else {
        this.salidaProducto.glosa = item.nombre;
      }
    },
    validate() {
      let validate = true;
      if (this.salidaProducto.stock.saldo_up < this.salidaProducto.cantidad) {
        util.showNotify('La cantidad requerida no es disponible en stock!', 'warn');
        validate = false;
      }

      if (this.stockLotes.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        let cantidad = this.salidaProducto.cantidad;
        this.salidaProducto.stockLotes = this.stockLotes;
        for (let index = 0; index < this.stockLotes.length; index + 1) {
          const ele = this.stockLotes[index];
          cantidad -= ele.disponibles;
          // this.salidaProducto.stockLotes.push(ele);
          if (cantidad <= 0) {
            break;
          }
        }
        if (cantidad > 0) {
          util.showNotify('La cantidad requerida no es disponible en stock por Lotes!', 'warn');
          validate = false;
        }
      }
      return validate;
    },
    reset() {
      this.salidaProducto.stock = { saldo_ua: 0, saldo_up: 0 };
      this.salidaProducto.stockLotes = [];
      this.salidaProducto.glosa = '';
      this.salidaProducto.unidad = false;
      this.salidaProducto.cantidad = 1;
      this.salidaProducto.producto = null;
      if (this.$refs.unidad) {
        this.$refs.unidad.checked = false;
      }
    },
    onlyNumber(event) {
      const tecla = (document.all) ? event.keyCode : event.which;
      if (tecla < 13) {
        return true;
      }
      if (tecla === 13) {
        return event.preventDefault();
      }
      return this.isNumber(String.fromCharCode(tecla)) ? true : event.preventDefault();
    },
    isNumber(cantidad) {
      const expresion = /^\d+$/;
      return expresion.test(cantidad);
    },
    agregarDetalle(tipo) {
      // tipo = 1 insumo, tipo = 2 ventas
      const productoObj = this.salidaProducto;
      const detalle = {
        producto_numero: productoObj.producto.numero,
        codigo: productoObj.producto.codigo,
        producto_id: productoObj.producto.id,
        producto_nombre: productoObj.producto.nombre,
        moneda_id: productoObj.producto.moneda_id,
        unidad_medida_id: productoObj.producto.unidad_medida.id,
        unidad_medida_descripcion: productoObj.producto.unidad_medida.descripcion,
        descripcion: productoObj.producto.descripcion,
        glosa: productoObj.glosa,
        cantidad: productoObj.cantidad,
        fecha: new Date(),
        usuario: this.user.name,
        tipo_consumo: tipo,
      };
      console.log('detalle', detalle);
      if (!productoObj.producto.precios[0]) {
        util.showNotify(`El producto ${productoObj.producto.nombre} debe tener un precio valido!`, 'warn');
        detalle.producto_precio = 0;
      } else {
        detalle.producto_precio = parseFloat(productoObj.producto.precios[0].monto);
      }
      this.addDetalle(productoObj.stockLotes, detalle);
    },
    addDetalle(stockLotes, detalle) {
      let cantidad = parseInt(detalle.cantidad, 10);
      for (let index = 0; index < stockLotes.length;) {
        const newDetalle = this.cloneObject(detalle);
        const element = stockLotes[index];
        const diferencia = Math.round(element.disponibles) - cantidad;
        cantidad = (diferencia > 0) ? cantidad : cantidad - Math.round(element.disponibles);
        newDetalle.cantidad = (diferencia > 0) ? cantidad : Math.round(element.disponibles);
        newDetalle.tc = element.tc;
        newDetalle.lote_id = element.lote_id;
        newDetalle.lote_numero = element.lote_numero;
        newDetalle.costo_unitario = parseFloat(element.costo_unitario);
        newDetalle.total = newDetalle.cantidad * newDetalle.costo_unitario;
        newDetalle.tipo_consumo = detalle.tipo_consumo;
        this.detalles.push(newDetalle);
        console.log('thi.detalles', this.detalles);
        index += 1;
        if (diferencia >= 0) {
          break;
        }
      }
    },
    cloneObject(object) {
      const objectString = JSON.stringify(object);
      return JSON.parse(objectString);
    },
    actualizarStock() {
      let stockUtilizado = 0;
      this.detalles.forEach((ele) => {
        if (ele.producto_id === this.salidaProducto.producto.id) {
          stockUtilizado += ele.cantidad;
        }
      });
      this.salidaProducto.stock.saldo_up -= stockUtilizado;

      for (let index = 0; index < this.stockLotes.length;) {
        const ele = this.stockLotes[index];
        index += 1;
        if (stockUtilizado >= Math.round(ele.disponibles)) {
          stockUtilizado -= Math.round(ele.disponibles);
          ele.disponibles = 0;
        } else {
          ele.disponibles -= stockUtilizado;
          stockUtilizado = 0;
        }
        if (stockUtilizado === 0) {
          break;
        }
      }
    },
    delDetalle(index) {
      this.detalles.splice(index, 1);
    },
  },
  computed: {
    showCargarMas() {
      const isOkToSearch = this.temporaPageComboProducto * 100 < this.totalRegistro;
      return this.totalRegistro > 100 && isOkToSearch;
    },
    ...mapState('main', [
      'user',
    ]),
  },
  watch: {
  },
  components: {
    Multiselect2,
  },
};
</script>

<style scoped>
.btnOrange {
  color: white;
  background-color: #F4B375;
}
.btnTurquesa {
  color: white;
  background-color: #319ba1;
}
</style>
