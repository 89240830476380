<template>
  <div v-if="isLoading">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-sm-5">
        <div class="breadcrumb">
          <h1>Nuevo Tratamiento</h1>
        </div>
      </div>
      <!-- aqui va la transicion con la barra informativa superior -->
      <transition name="fade" v-if="showCortinaInformativa">
        <div class="col-sm-7 navbar-reubicado">
          <div class="row">
            <div class="col-sm-12 py-2 bg-sky-light text-white">
              <div class="row">
                <div class="col-2">
                  <p style="font-size: 12px"> F/Nac.: {{ dataPaciente.fecha_nacimiento }} </p>
                </div>
                <div class="col-4" v-show="dataPaciente.contacto">
                  <p style="font-size: 12px">Contacto 1: {{ dataPaciente.contacto }} </p>
                </div>
                <div class="col-4">
                  <p style="font-size: 12px" v-if="dataPaciente.familiar">
                    Familiar 1:
                    {{ dataPaciente.familiar }} </p>
                </div>
              </div>
            </div>
            <!-- <div class="col-sm-1 bg-sky-light pl-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="36"
                fill="currentColor"
                class="bi bi-caret-down-fill text-success" viewBox="0 0 16 16">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4
                 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
            </div> -->
          </div>
        </div>
      </transition>
    </div>
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="card text-left">
          <div class="card-header">
            <div class="col-sm-5">
              <button
                type="submit"
                class="btn bg-custom-green mr-2 rounded"
                @click="store"
              >
                Guardar
              </button>
              <router-link :to="{ name: 'tratamiento-index' }">
                <button
                  class="btn btn-white border border-light rounded">
                  Regresar
                </button>
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <b-card class="mb-3">
                  <div class="row mb-3">
                    <div class="col-sm-2 text-right pr-0">
                      <label class="col-form-label">
                        Fecha:
                      </label>
                    </div>
                    <div class="col-sm-4 px-0">
                      <date-picker
                        class="col-sm-10"
                        v-model="fecha"
                        lang="es"
                        format="DD/MM/YYYY"
                        type="date"
                        value-type="date"
                      ></date-picker>
                    </div>
                    <div class="col-sm-2 text-right pr-0">
                      <label class="col-form-label">
                        Fecha Cierre:
                      </label>
                    </div>
                    <div class="col-sm-4 px-0">
                      <date-picker
                        class="col-sm-10"
                        v-model="fechaCierre"
                        lang="es"
                        format="DD/MM/YYYY"
                        type="date"
                        value-type="date"
                      ></date-picker>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-2 text-right pr-0">
                      <label class="col-form-label">
                        Paciente:
                      </label>
                    </div>
                    <ComboPaciente
                      v-on:addPaciente="AddPaciente"
                      :havePreCierre="preCierre"
                    />
                    <div class="col-sm-1 px-0">
                      <button
                        class="btn btn-success col-sm-12 px-0"
                        @click="openModalPaciente"
                      >
                        <i class="i-Business-ManWoman h5"></i>
                      </button>
                      <ModalPacienteNuevo />
                    </div>
                  </div>
                </b-card>
                <b-card class="mb-3">
                  <div class="row mb-3">
                    <div class="col-sm-2 text-right pr-0">
                      <label class="col-form-label">
                        H.C:
                      </label>
                    </div>
                    <div class="col-sm-2 pr-0">
                      <input
                        type="text"
                        class="form-control"
                        v-model="dataPaciente.numero"
                        disabled
                      >
                    </div>
                    <div class="col-sm-1 px-0 text-right">
                      <label class="col-form-label">
                        Edad:
                      </label>
                    </div>
                    <div class="col-sm-2 pr-1">
                      <input
                        type="text"
                        class="form-control"
                        v-model="edad"
                        disabled
                      >
                    </div>
                    <div class="col-sm-2 pl-1">
                      <input
                        v-model="mes"
                        type="text"
                        class="form-control"
                        disabled
                      >
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-sm-2 text-right pr-0">
                      <label class="col-form-label text-light">
                        Almacen:
                      </label>
                    </div>
                    <div class="col-sm-7">
                      <multiselect2
                        v-model="almacen.model"
                        selectLabel=""
                        selectedLabel=""
                        deselect-label="X"
                        track-by="almacen"
                        label="almacen"
                        placeholder="Seleccione Almacen"
                        :options="almacen.listaAlmacenes"
                        :disabled="isDisabledAlmacen"
                        :searchable="true">
                      </multiselect2>
                    </div>
                  </div>
                  <div class="row mb-3" v-if="isDisableSeguro">
                    <div class="col-sm-2 text-right pr-0">
                      <label class="col-form-label text-light">
                        Seguro:
                      </label>
                    </div>
                    <div class="col-sm-7">
                      <multiselect2
                        v-model="seguro.model"
                        selectLabel=""
                        selectedLabel=""
                        deselect-label="X"
                        track-by="nombre_comercial"
                        label="nombre_comercial"
                        placeholder="Seleccione un seguro"
                        :options="seguro.listaSeguros"
                        :allow-empty="false"
                        :searchable="true">
                      </multiselect2>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-sm-2 pr-1">
                      <button
                        class="btn col-sm-12"
                        :class="getIDItemChecked !== null? 'btn-success':'btn-warning'"
                        @click="openModalPieza"
                      >
                        Pieza
                      </button>
                      <ModalPieza :editMode="false"/>
                    </div>
                    <div class="col-sm-3 pr-1">
                      <input
                        :value="getCamaItemChecked"
                        type="text"
                        class="form-control text-center px-1"
                        disabled
                      >
                    </div>
                    <div class="col-sm-4 pl-1">
                      <input
                        :value="getPisoSalaItemChecked"
                        type="text"
                        class="form-control text-center px-1"
                        disabled
                      >
                    </div>
                  </div>
                </b-card>
                <b-card class="mb-4">
                  <div class="row mb-3">
                    <div class="col-sm-3 px-0 ml-2 text-right">
                      <label class="col-form-label text-skyblue font-weight-bold">
                       Tratamiento Principal:
                      </label>
                    </div>
                    <div class="col-sm-8">
                      <multiselect2
                        v-model="categoriaTratamiento.model"
                        selectLabel=""
                        selectedLabel=""
                        deselect-label="X"
                        track-by="nombre"
                        label="nombre"
                        multiple
                        placeholder="Seleccione tratamientos"
                        :options="categoriaTratamiento.listaCategorias"
                        :allow-empty="false"
                        :searchable="true">
                      </multiselect2>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-3 px-0 ml-2 text-right">
                      <label class="col-form-label text-skyblue font-weight-bold">
                       Medico Principal:
                      </label>
                    </div>
                    <div class="col-sm-8">
                      <multiselect2
                        v-model="medico.model"
                        selectLabel=""
                        selectedLabel=""
                        deselect-label="X"
                        track-by="nombre"
                        label="nombre"
                        placeholder="Seleccione un medico"
                        :options="medico.listaMedicos"
                        :allow-empty="false"
                        multiple
                        :searchable="true">
                      </multiselect2>
                    </div>
                  </div>
                </b-card>
                <div class="row">
                  <div class="col-sm-3 offset-sm-3 pl-0">
                    <TheModalPreCierre
                    ref="theModalPreCierre"
                    :servicios_internos="servicios"
                    :cxp="cuentas_x_pagar"
                    :paciente="dataPaciente"
                    :preCierreData="preCierreData"
                    :salidProducto="salidaProductos.detalles"
                    :moneda="moneda"
                    @agregarPrecierre="agregarPrecierre"
                    @update:estado_precierre="preCierre=$event"
                    v-on:limpiarPreCierre="limpiarPreCierre"
                    @agregarCierre="agregarCierre"
                    />
                  </div>
                  <div class="col-sm-3 pr-0">
                    <ModalCierre
                    :preCierre="preCierre"
                    :preCierreData="preCierreData"
                    :cierreData="cierre"
                    @agregarCierre="agregarCierre"/>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="row mb-2">
                  <div class="col-sm-12 border border-light py-2 px-4">
                    <div class="row mb-1">
                      <div class="col-sm-12 bg-light text-white rounded">
                        <div class="row">
                          <div class="col-sm-11 h4">
                            <label class="col-form-label">
                              Consumo de Productos (SP)
                            </label>
                          </div>
                          <div class="col-sm-1 pl-0 pt-2">
                            <!-- <button class="btn btn-success pr-3">+</button> -->
                            <the-modal-consumo-productos
                              :almacen="almacen.model"
                              :moneda="moneda.model"
                              :fecha="fecha"
                              :havePreCierre="preCierre"
                              v-on:addProducto="addProducto"/>
                          </div>
                        </div>
                      </div>
                    </div>
                      <!-- this.productos_sp -->
                    <div class="row">
                      <div class="col-sm-12 rounded th-custom-color text-black py-2">
                        <div class="row">
                          <div class="col-sm-1 font-weight-bold"></div>
                          <div class="col-sm-3 font-weight-bold">Fecha</div>
                          <div class="col-sm-6 font-weight-bold">Productos</div>
                          <div class="col-sm-2 font-weight-bold text-center">Cantidad</div>
                        </div>
                      </div>
                      <div class="col-sm-12"
                        :class="productos_sp.length > 2? 'tabla-scroll-vertical': ''">
                        <template v-if="productos_sp.length > 0">
                          <div class="row mb-2 border-light pt-2"
                            v-for="(item, index) in productos_sp" :key="''+item.id + index"
                            :class="index === 0 ? '': 'border-top'">
                            <div class="col-sm-1 pr-0">
                              <i class="nav-icon i-Close-Window text-danger font-weight-bold"
                                v-if="!preCierre"
                                style="cursor: pointer"  @click="eliminarSalidaProducto(index)"></i>
                                <i v-else
                                class="nav-icon i-Close-Window font-weight-bold"
                                style="cursor: pointer"
                                :title="messagePreCierre"
                                ></i>
                            </div>
                            <div class="col-sm-3 p-0">
                              {{ getFechaFormat(item.fecha, 'DD/MM/YYYY HH:mm') }} <br>
                              {{ item.usuario }}
                            </div>
                            <div class="col-sm-6 p-0">
                              {{item.producto}} <strong>Glosa: </strong> {{ item.glosa }}
                            </div>
                            <div class="col-sm-2 text-center">
                              <button
                              :class="item.tipo_consumo === 1
                              ? 'btn btnOrange' : 'btn btnTurquesa'">
                                {{item.cantidad}}
                              </button>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div
                            class="row mb-2 border-light pt-2 border-top">
                            <div class="col-sm-12 text-center">
                              No hay consumos productos registrados
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-12 border border-light py-2 px-4">
                    <div class="row mb-1">
                      <div class="col-sm-12 bg-light text-white rounded">
                        <div class="row">
                          <div class="col-sm-11 h4">
                            <label class="col-form-label">
                              Servicios Internos (VS)
                            </label>
                          </div>
                          <div class="col-sm-1 pl-0 pt-2">
                            <TheModalServicioInterno
                            :empresa_moneda="empresa_moneda"
                            :medic="medico.listaMedicos"
                            :servicios_internos="tipo_servicios_internos"
                            :havePreCierre="preCierre"
                            @addServiciosInternos="addServiciosInternos"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 rounded th-custom-color text-black py-2">
                        <div class="row">
                          <div class="col-sm-1 font-weight-bold"> &nbsp;&nbsp;</div>
                          <div class="col-sm-3 font-weight-bold">Fecha</div>
                          <div class="col-sm-6 font-weight-bold">Servicios</div>
                          <div class="col-sm-2 font-weight-bold text-center">Hora</div>
                        </div>
                      </div>
                      <div class="col-sm-12"
                        :class="servicios.length > 2? 'tabla-scroll-vertical': ''">
                        <template v-if="servicios.length > 0">
                          <div
                            class="row mb-2 border-light pt-2"
                            v-for="(item, index) in servicios" :key="item.id"
                            :class="index === 0 ? '': 'border-top'"
                          >
                            <div class="col-sm-1">
                              <i class="nav-icon i-Close-Window text-danger font-weight-bold"
                                style="cursor: pointer"
                                v-if="!preCierre"
                                @click="eliminarServiciosInternos(index)"></i>
                                <i v-else
                                class="nav-icon i-Close-Window font-weight-bold"
                                style="cursor: pointer"
                                :title="messagePreCierre"
                                ></i>
                            </div>
                            <div class="col-sm-3">
                              {{item.venta_servicio_fecha}}
                              <br>
                              <b>User: </b> {{ item.usuario }}
                            </div>
                            <div class="col-sm-6">
                              {{item.tipo_servicio_nombre}}
                              <br>
                              <b>Glosa: </b> {{ item.textoglosa }}
                            </div>
                            <div class="col-sm-2 text-center">
                              {{item.hora}}
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div
                            class="row mb-2 border-light pt-2 border-top">
                            <div class="col-sm-12 text-center">
                              No hay servicios internos registrados
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 border border-light py-2 px-4">
                    <div class="row mb-1">
                      <div class="col-sm-12 bg-light text-white rounded">
                        <div class="row">
                          <div class="col-sm-11 h4">
                            <label class="col-form-label">
                              Servicios Externos (CxP)
                            </label>
                          </div>
                          <div class="col-sm-1 pl-0 pt-2">
                            <!-- <button class="btn btn-success pr-3">+</button> -->
                            <the-modal-servicio-externo
                              :havePreCierre="preCierre"
                              :data="dataModalCXP"
                              v-on:addCuentaPorPagar="addCuentaPorPagar"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 rounded th-custom-color text-black py-2">
                        <div class="row">
                          <div class="col-sm-1 font-weight-bold"> &nbsp;&nbsp;</div>
                          <div class="col-sm-3 font-weight-bold">Fecha</div>
                          <div class="col-sm-6 font-weight-bold">Servicios</div>
                          <div class="col-sm-2 font-weight-bold text-center">Hora</div>
                        </div>
                      </div>
                      <div class="col-sm-12"
                        :class="cuentas_x_pagar.length > 2? 'tabla-scroll-vertical': ''">
                        <template v-if="cuentas_x_pagar.length > 0">
                          <div class="row mb-2 border-light pt-2"
                            v-for="(item, index) in cuentas_x_pagar" :key="''+item.id + index"
                            :class="index === 0 ? '': 'border-top'">
                            <div class="col-sm-1">
                              <i class="nav-icon i-Close-Window text-danger font-weight-bold"
                                v-if="!preCierre"
                                style="cursor: pointer"  @click="eliminarCuentaPorPagar(index)"></i>
                              <i v-else
                              class="nav-icon i-Close-Window font-weight-bold"
                              style="cursor: pointer"
                              :title="messagePreCierre"
                              ></i>
                            </div>
                            <div class="col-sm-3">
                              {{ getFechaFormat(item.fecha, 'DD/MM/YYYY')}} <br>
                              {{item.usuario}}
                            </div>
                            <div class="col-sm-6">
                              {{item.origen_type}} - Dr. {{item.medico.nombre}}
                              <strong>Desc.: </strong>
                              {{item.glosa  ? item.glosa : item.descripcion}}
                            </div>
                            <div class="col-sm-2 text-center">
                              {{ getFechaFormat(item.created_at, 'HH:mm')}}
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div
                            class="row mb-2 border-light pt-2 border-top">
                            <div class="col-sm-12 text-center">
                              No hay servicios externos registrados
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store/index';
import Multiselect2 from '@/components/util/vue-multiselect2';
import DatePicker from 'vue2-datepicker';
import axios from '@/modules/common/axiosERP';
import moment from 'moment';
import { util } from '@/plugins/util';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import ComboPaciente from '../components/ThePatient.vue';
import ModalPacienteNuevo from '../components/TheModalPacienteNuevo.vue';
import ModalPieza from '../components/TheModalPieza.vue';
import TheModalServicioExterno from '../components/TheModalServicioExterno.vue';
import TheModalConsumoProductos from '../components/TheModalConsumoProductos.vue';
import TheModalServicioInterno from '../components/TheModalServicioInterno.vue';
import TheModalPreCierre from '../components/TheModalPreCierre.vue';
import ModalCierre from '../components/TheModalCierre.vue';

export default {
  name: 'create',
  components: {
    Multiselect2,
    DatePicker,
    ComboPaciente,
    ModalPacienteNuevo,
    ModalPieza,
    TheModalServicioExterno,
    TheModalConsumoProductos,
    TheModalServicioInterno,
    TheModalPreCierre,
    ModalCierre,
  },
  data() {
    return {
      isLoading: false,
      fecha: new Date(),
      pacienteSelected: null,
      // pacientes: [],
      productos_sp: [],
      salidaProductos: {
        moneda_id: null,
        cuenta_contable_id: null,
        total: 0,
        detalles: [],
      },
      servicios: [],
      fechaCierre: new Date(),
      medico: {
        listaMedicos: [],
        model: [],
      },
      seguro: {
        listaSeguros: [],
        model: null,
      },
      almacen: {
        listaAlmacenes: [],
        model: null,
      },
      categoriaTratamiento: {
        listaCategorias: [],
        model: null,
      },
      dataPaciente: {},
      showCortinaInformativa: false,
      edad: '',
      mes: '',

      // CXP
      moneda: {
        model: null,
        multiselect: [],
        nuevo_tipo_cambio: null,
        tc_secundario: null,
      },
      cxp: {},
      dataModalCXP: {},
      cuentas_x_pagar: [],
      field: [
        {
          key: 'eliminar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'fecha', label: 'Fecha', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '15%' },
        },
        {
          key: 'tipo_servicio_nombre', label: 'Servicios', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '%' },
        },
        {
          key: 'venta_servicio_cantidad', label: 'Cantidad', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '%' },
        },
      ],
      // servicios_internos: [],
      preCierre: false,
      anticipo: 0,
      tipo_servicios_internos: [],
      preCierreData: {
        total_precierre: 0,
        saldo_precierre: 0,
        anticipo_precierre: 0,
        glosa_precierre: null,
        estado_precierre: false,
        custom_id: null,
        fecha: null,
        hora: null,
        usuario: null,
      },
      cierre: {
        fecha: null,
        hora: null,
        glosa: null,
        estado: false,
      },
      empresa_moneda: null,
      messagePreCierre: 'Acción no permita usted tiene un precierre',
      isDisableSeguro: store.getters['main/hasPermisionListarSeguro'],
    };
  },
  created() {
    this.getData();
  },
  beforeDestroy() {
    this.resetModalPieza();
  },
  methods: {
    openModalPaciente() {
      this.$bvModal.show('modal-pacienteNuevo');
    },
    getData() {
      this.isLoading = true;
      axios.get('clinic/tratamientos/create')
        .then((response) => {
          this.medico.listaMedicos = response.data.data.medicos;
          this.seguro.listaSeguros = response.data.data.seguros;
          this.almacen.listaAlmacenes = response.data.data.almacens;
          this.almacen.model = response.data.data.almacens.length === 1
            ? response.data.data.almacens[0] : null;
          this.categoriaTratamiento.listaCategorias = response.data.data.categoria_tratamientos;
          this.cargarModalPieza(response.data.data.sala_camas);
          // carga datos Modal cxp
          this.moneda.multiselect = response.data.data.monedas;
          this.moneda.model = this.loadMonedaPrincipal(this.moneda.multiselect);
          this.dataModalCXP.medicos = response.data.data.medicos;
          // this.dataModalCXP.medicos = this.medico.listaMedicos;
          this.dataModalCXP.monedas = this.moneda;
          this.dataModalCXP.parametrosContables = response.data.data.pametrosContables;
          this.dataModalCXP.tipo_comprobantes = response.data.data.tipo_comprobante
            .filter((ele) => (ele.id === 1 || ele.id === 4));
          this.dataModalCXP.cuenta_credito_fiscal = response.data.data.cuenta_credito_fiscal;
          this.dataModalCXP.tipoFacturas = response.data.data.tipo_facturas;
          this.tipo_servicios_internos = response.data.data.tipo_servicios;
          this.empresa_moneda = response.data.data.monedas;
        })
        .catch((error) => {
          console.log(error);
          util.showNotify(error.response.data.message, 'error');
        }).finally(() => {
          this.isLoading = false;
        });
    },
    AddPaciente(paciente) {
      if (paciente) {
        this.dataPaciente = paciente.datosPaciente;
        this.edad = (this.dataPaciente.edad) ? `${this.dataPaciente.edad} años` : '0 años';
        this.mes = (this.dataPaciente.meses) ? `${this.dataPaciente.meses} mes` : '0 mes';
        this.showCortinaInformativa = true;
        this.pacienteSelected = paciente.pacienteSelect;
        this.preCierreData.anticipo_precierre = (this.dataPaciente.anticipo)
          ? this.dataPaciente.anticipo : 0;
      } else {
        this.edad = '';
        this.mes = '';
        this.showCortinaInformativa = false;
        this.pacienteSelected = '';
        this.dataPaciente = '';
      }
    },
    store() {
      if (this.validar()) {
        this.preCierreData.estado_precierre = this.preCierre;
        this.cargarDatosPreCierre();
        const DATA = {
          paciente_id: this.pacienteSelected.id,
          fecha: this.fecha,
          fecha_cierre: this.fechaCierre,
          almacen_id: (this.almacen.model) ? this.almacen.model.id : null,
          seguro_id: (this.seguro.model) ? this.seguro.model.id : '',
          tratamiento_medicos: this.medico.model,
          categoria_tratamientos: this.categoriaTratamiento.model,
          cuentas_x_pagar: (this.cuentas_x_pagar.length > 0) ? this.cuentasPorPagarSendData() : [],
          monedas: this.moneda.multiselect,
          consumo_productos: this.salidaProductosSendData(),
          sala_cama_id: this.getIDItemChecked,
          venta_servicios: this.clearParams(),
          preCierre: this.preCierreData,
          cierre: this.cierre,
        };
        axios.post('clinic/tratamientos/store', DATA)
          .then((response) => {
            util.showNotify(response.data.message, 'success');
            this.$router.push('/tratamiento');
          })
          .catch((error) => {
            const FALLO_VALIDACION = 422;
            if (error.response.status !== FALLO_VALIDACION) {
              util.showNotify(error.response.data.message, 'error');
            } else {
              Object.entries(error.response.data.data).forEach(([, mensajes]) => {
                mensajes.forEach((texto) => util.showNotify(texto, 'error'));
              });
            }
          });
      }
    },
    clearParams() {
      this.servicios.forEach((e, index) => {
        delete this.servicios[index].customId;
        delete this.servicios[index].usuario;
        delete this.servicios[index].textoglosa;
        delete this.servicios[index].tipo_servicio_nombre;
      });
      return this.servicios;
    },
    validar() {
      let validate = true;
      if (!this.pacienteSelected) {
        util.showNotify('Seleccione un paciente', 'warn');
        validate = false;
      }
      if (!this.fecha) {
        util.showNotify('La fecha es un campo obligatorio', 'warn');
        validate = false;
      }
      if (!this.fechaCierre) {
        util.showNotify('La fecha de cierre es un campo obligatorio', 'warn');
        validate = false;
      }
      if ((this.productos_sp.length > 0 || this.cuentas_x_pagar.length > 0)
        && !this.almacen.model) {
        util.showNotify('Debe seleccionar un almacen. \n Si va registrar Consumo Productos o Servicios Externos', 'warn');
        validate = false;
      }
      // if (!this.seguro.model) {
      //   util.showNotify('Seleccione un seguro', 'warn');
      //   validate = false;
      // }
      if (this.medico.model.length === 0) {
        util.showNotify('Selecciones un medico', 'warn');
        validate = false;
      }
      if (!this.categoriaTratamiento.model) {
        util.showNotify('Seleccione un tratamiento', 'warn');
        validate = false;
      }
      if (this.fecha && this.fechaCierre) {
        if (this.fecha.getTime() > this.fechaCierre.getTime()) {
          util.showNotify('La fecha de cierre debe ser mayor que la fecha inicio', 'error');
          validate = false;
        }
      }
      return validate;
    },
    addCuentaPorPagar(cuentaPorPagar) {
      const cxpObj = JSON.stringify(cuentaPorPagar);
      this.cxp = JSON.parse(cxpObj);
      this.cuentas_x_pagar.push(this.cxp);
    },
    loadMonedaPrincipal(monedas) {
      for (let i = 0; i < monedas.length; i += 1) {
        if (monedas[i].is_principal === 1) {
          return monedas[i];
        }
      }
      return null;
    },
    cuentasPorPagarSendData() {
      const cxps = [];
      this.cuentas_x_pagar.forEach((cxp) => {
        cxps.push({
          persona_id: cxp.medico.persona_id,
          t_comprobante_id: cxp.tipo_comprobante.id,
          t_comprobante_child: cxp.t_comprobante_child,
          pre_retencion_tipo_id: null,
          nuevo_tipo_cambio: cxp.nuevo_tipo_cambio,
          fecha: cxp.fecha,
          fecha_hora: this.getFechaFormat(cxp.created_at, 'YYYY-MM-DD HH:mm:ss'),
          moneda_id: cxp.moneda.id,
          cuenta_pasivo_id: cxp.cuenta_pasivo_id,
          cuenta_gasto_id: cxp.cuenta_gasto_id,
          descripcion: cxp.glosa === '' ? null : cxp.glosa,
          importe: cxp.importe,
          retencion: cxp.retencion,
          importe_neto: cxp.importe_neto,
          descuento: cxp.descuento,
          fecha_vencimiento: cxp.fecha_vencimiento,
          factura: (cxp.tipo_comprobante.id === 1) ? cxp.factura : null,
          importe_actual: cxp.importe_actual,
        });
      });
      return cxps;
    },
    eliminarCuentaPorPagar(index) {
      this.cuentas_x_pagar.splice(index, 1);
    },
    getFechaFormat(dateString, formato) {
      return moment(dateString).format(formato);
    },
    addProducto(producto) {
      const productoObj = JSON.parse(JSON.stringify(producto));
      this.productos_sp = [];
      console.log('addpROUCTO', productoObj);
      for (let index = 0; index < productoObj.length;) {
        const ele = productoObj[index];
        console.log('ele', ele);
        index += 1;
        this.productos_sp.push({
          id: 1,
          fecha: ele.fecha,
          producto: ele.producto_nombre,
          glosa: ele.glosa,
          cantidad: ele.cantidad,
          usuario: ele.usuario,
          tipo_consumo: ele.tipo_consumo,
        });
        const moneda = this.moneda.multiselect.find((mo) => mo.id === ele.moneda_id);
        if (moneda.is_principal !== 1) {
          // eslint-disable-next-line operator-assignment
          ele.producto_precio = moneda.tc * ele.producto_precio;
        }
        ele.total_venta = ele.producto_precio * ele.cantidad;
      }
      this.salidaProductos.detalles = productoObj;
    },
    eliminarSalidaProducto(index) {
      this.productos_sp.splice(index, 1);
      this.salidaProductos.detalles.splice(index, 1);
      this.$emit('delDetalle', index);
    },
    salidaProductosSendData() {
      this.salidaProductos.moneda_id = this.moneda.model.id;
      this.salidaProductos.cuenta_contable_id = this.dataModalCXP
        .parametrosContables.cuenta_debe_sp;
      // const DETALLES = this.salidaProductos.detalles;
      let total = 0;
      for (let index = 0; index < this.salidaProductos.detalles.length;) {
        const ele = this.salidaProductos.detalles[index];
        index += 1;
        ele.fecha = this.getFechaFormat(ele.fecha, 'YYYY-MM-DD HH:mm:ss');
        total += parseFloat(ele.total);
      }
      this.salidaProductos.total = total;
      return this.salidaProductos;
    },
    openModalPieza() {
      this.$bvModal.show('modal-pieza');
    },
    openModalCierre() {
      this.$bvModal.show('modal-cierre');
    },
    ...mapMutations('modalPieza', [
      'cargarModalPieza',
    ]),
    ...mapActions('modalPieza', [
      'resetModalPieza',
    ]),
    addServiciosInternos(data) {
      const service = data.servicios;
      this.servicios.push(service);
    },
    eliminarServiciosInternos(index) {
      this.servicios.splice(index, 1);
    },
    agregarPrecierre(data) {
      this.preCierreData.total_precierre = data.total;
      this.preCierreData.saldo_precierre = data.saldo;
      this.preCierreData.anticipo_precierre = data.anticipo;
      this.preCierreData.glosa_precierre = data.glosa;
      this.preCierreData.estado_precierre = data.estado_pre_cierre;
      this.preCierreData.fecha = data.fecha;
      this.preCierreData.hora = data.hora;
      this.preCierre = data.isPrecierre;
    },
    limpiarPreCierre() {
      this.preCierreData.total_precierre = 0;
      // this.preCierreData.anticipo_precierre = 0;
      this.preCierreData.saldo_precierre = 0;
      this.preCierreData.glosa_precierre = null;
      this.preCierreData.estado_precierre = false;
      this.preCierreData.custom_id = new Date().getTime();
      // marcar el estado del cierre como false;
      this.cierre.estado = false;
      this.cierre.hora = null;
      this.cierre.glosa = null;
    },
    agregarCierre(data) {
      this.cierre.fecha = data.fecha;
      this.cierre.hora = data.hora;
      this.cierre.glosa = data.glosa;
      this.cierre.estado = data.estado;
    },
    cargarDatosPreCierre() {
      const TOTAL = parseFloat(this.serviciosInternos) + parseFloat(this.centasXPagar)
      + parseFloat(this.consumoProducto);
      const SALDO = parseFloat(TOTAL) - parseFloat(this.preCierreData.anticipo_precierre);
      this.preCierreData.total_precierre = TOTAL;
      this.preCierreData.saldo_precierre = SALDO;
    },
  },
  computed: {
    ...mapGetters('modalPieza', [
      'getIDItemChecked',
      'getCamaItemChecked',
      'getPisoSalaItemChecked',
    ]),
    isDisabledAlmacen() {
      return this.salidaProductos.detalles.length > 0;
    },
    ...mapState('main', [
      'user',
    ]),
    serviciosInternos() {
      let total = 0;
      this.servicios.forEach((item) => {
        total += parseFloat(item.importe);
      });
      return total;
    },
    centasXPagar() {
      let total = 0;
      this.cuentas_x_pagar.forEach((item) => {
        const tipoMoneda = this.moneda.multiselect.find(
          (el) => el.id === item.moneda_id,
        );
        if (tipoMoneda.is_principal === 0) {
          total += parseFloat(item.importe) * parseFloat(tipoMoneda.tc);
        } else {
          total += parseFloat(item.importe);
        }
      });
      return total;
    },
    consumoProducto() {
      let total = 0;
      this.salidaProductos.detalles.forEach((item) => {
        if (item.tipo_consumo === 2) {
          total += parseFloat(item.total_venta);
        }
      });
      return total;
    },
  },
  watch: {
    // pacienteSelected() {
    //   this.$refs.theModalPreCierre.restaurar();
    //   this.preCierre = false;
    // },
  },
};
</script>

<style scoped>
.btn-white{
  background: white;
  color: black;
}
.text-skyblue
{
  color: #61c6f2;
}
.bg-sky-light
{
  background:#a6d2eb;
}
.navbar-reubicado
{
  transform: translate(0px, -32px);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.tabla-scroll-vertical {
    overflow-y: scroll;
    height: 180px;
}
.btnOrange {
  color: white;
  background-color: #F4B375;
}
.btnTurquesa {
  color: white;
  background-color: #319ba1;
}
</style>
